<template>
    <div class="auth-form">
        <div class="d-flex flex-wrap align-items-center">
            <div class="col-12 mt-2 mb-4">
                <h6 class="text-white">
                    {{ $t('auth.emailSend.instructions') }}
                </h6>
            </div>
            <div class="col-12 mb-4">
                <label for="email">
                    <i class="fas fa-envelope"></i>
                </label>
                <input v-model="email" @keyup="validateEmail" name="email" type="email" class="form-control" :placeholder="$t('auth.yourEmail')" :class="errors.email ? 'is_invalid' : ''">
                <small v-if="errors.email" class="is_invalid">
                    <i class="fas fa-exclamation-triangle me-2"></i>
                    {{ errors.email }}
                </small>
            </div>  
            <div class="col-12">
                <span class="btn my-a text-white w-100" @click="send_request">
                    <i class="far fa-envelope-open me-2"></i>
                    {{ $t('auth.emailSend.send') }}
                </span>
            </div>
            <div class="col-12 mt-2">
                <router-link to="/log/in" class="btn btn-success w-100">
                    <i class="fas fa-history me-2"></i>
                    {{ $t('auth.back') }}
                </router-link>
            </div>
        </div>
    </div>
    <transition name="fade-modal">
        <modal v-if="successModal" @close="successModal = false">
            <template v-slot:header>
                <div class="modal-header bg-success border-0 rounded-0 text-light">
                    <div class="d-flex align-items-center">
                        <i class="far fa-check-circle me-2 fs-4"></i>
                        <h5 v-if="this.$route.params.request === 'forgot_password'" class="modal-title">
                            {{ $t('modal.successModal.header.emailSend.forgot_password') }}: 
                            <strong class="text-dark"> 
                                {{ email }}
                            </strong>
                        </h5>
                        <h5 v-else class="modal-title">
                            {{ $t('modal.successModal.header.emailSend.confirm_email') }}: 
                            <strong class="text-dark"> 
                                {{ email }}
                            </strong>
                        </h5>
                    </div>
                </div>
            </template>
        </modal>
    </transition>
    <transition name="fade-modal">
        <modal v-if="wrongModal" @close="wrongModal = false">
            <template v-slot:header>
                <div class="modal-header bg-danger border-0 rounded-0 text-light">
                    <div class="d-flex align-items-center">
                        <i class="fas fa-exclamation-circle me-2"></i>
                        <h5 class="modal-title">
                            {{ $t('modal.wrongModal.header.emailSend') }}
                        </h5>
                    </div>
                </div>
            </template>
        </modal>
    </transition>
</template>

<script>
import { mapGetters } from "vuex"
import Modal from '../components/Modal.vue'
export default {
    name: 'EmailService',
    components: {
        Modal
    },
    data() {
        return {
            email: '',
            errors: {
                email: ''
            },
            successModal: false,
            wrongModal: false
        }
    },
    computed: {
        ...mapGetters(["apiPath"])
    },
    methods: {
        validEmail (email) {
            var re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            return re.test(email);
        },
        validateEmail () {
            if (this.email.length == 0) {
                return this.errors.email = this.$t('errors.required')
            } else if (!this.validEmail(this.email)) {
                return this.errors.email = this.$t('errors.email.invalid')
            } else {
                delete this.errors.email
            }
        },
        send_request() {
            this.$http
                .post(`${this.apiPath}/account/${this.$route.params.request}_request`, { email: this.email }, {
                    headers: {
                        'x-api-key': this.$store.state.config.oms_api.key
                    }
                })
                .then(
                    (res) => {
                        if (res.data.errors) {
                            this.validateEmail()
                        } else if (res.data == 'account already validated') {
                            this.errors.email = this.$t('errors.email.already_confirmed')
                        } else if (res.data == 'account not exist') {
                            this.errors.email = this.$t('errors.email.notExist')
                        } else {
                            this.successModal = true
                            setTimeout(() => {
                                this.successModal = false
                                this.$router.replace('/log/in')
                            }, 1500)
                        }
                    }
                )
                .catch((err) => {
                    this.$store.state.serverModal = true
                    this.$store.state.errServer = err.message
                })
        }
    }
}
</script>

<style lang="scss" scoped>
    .my-a {
        background-color: #f7911d;

        &:hover {
            background-color: #f7911d;
        }
    }
</style>