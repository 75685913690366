<template>
    <form @submit.prevent="signUp" class="auth-form">
        <div class="d-flex flex-wrap align-items-center">
            <div class="col-12 mb-4">
                <label for="name">
                    <i class="fas fa-user cursor-pointer"></i>
                </label>
                <input v-model="name" @keyup="validateName" name="name" type="text" class="form-control" id="name" :placeholder="$t('auth.yourName')" autocomplete="off" :class="errors.name ? 'is_invalid' : ''">
                <small v-if="errors.name" class="is_invalid">
                    <i class="fas fa-exclamation-triangle me-2"></i>
                    {{ errors.name }}
                </small>
                <input v-model="surname" @keyup="validateSurname" name="surname" type="text" class="form-control mt-2" :placeholder="$t('auth.yourSurname')" autocomplete="off" :class="errors.surname ? 'is_invalid' : ''">
                <small v-if="errors.surname" class="is_invalid">
                    <i class="fas fa-exclamation-triangle me-2"></i>
                    {{ errors.surname }}
                </small>
            </div>
            <div class="col-12 mb-4">
                <label for="email">
                    <i class="fas fa-envelope cursor-pointer"></i>
                </label>
                <input v-model="email" @keyup="validateEmail" name="email" type="email" class="form-control" id="email" :placeholder="$t('auth.yourEmail')" autocomplete="off" :class="errors.email ? 'is_invalid' : ''">
                <small v-if="errors.email" class="is_invalid">
                    <i class="fas fa-exclamation-triangle me-2"></i>
                    {{ errors.email }}
                </small>
                <input v-model="repeat_email" @keyup="validateRepEmail" name="repeat_email" type="email" class="form-control  mt-2" :placeholder="$t('auth.repEmail')" autocomplete="off" :class="errors.repeat_email ? 'is_invalid' : ''">
                <small v-if="errors.repeat_email" class="is_invalid">
                    <i class="fas fa-exclamation-triangle me-2"></i>
                    {{ errors.repeat_email }}
                </small>
            </div>
            <div class="col-12 mb-4">
                <label for="password">
                    <i class="fas fa-lock cursor-pointer"></i>
                </label>
                <div class="position-relative">
                    <input v-model="password" @keyup="validatePassword" name="password" type="password" class="form-control input-psw" id="password" :placeholder="$t('auth.yourPassword')" autocomplete="new-password" :class="errors.password ? 'is_invalid' : ''">
                    <i @click="showPsw" class="far fa-eye cursor-pointer" v-if="showPassword" :class="errors.password ? 'text-danger' : ''"></i>
                    <i @click="showPsw" class="far fa-eye-slash cursor-pointer" v-else :class="errors.password ? 'text-danger' : ''"></i>
                </div>
                <small v-if="errors.password" class="is_invalid">
                    <i class="fas fa-exclamation-triangle me-2"></i>
                    {{ errors.password }}
                </small>
                <div class="position-relative">
                    <input v-model="repeat_password" @keyup="validateRepPassword" name="repeat_password" type="password" class="form-control input-psw mt-2" :placeholder="$t('auth.repPassword')" autocomplete="new-password" :class="errors.repeat_password ? 'is_invalid' : ''">
                    <i @click="showPsw" class="far fa-eye cursor-pointer" v-if="showPassword" :class="errors.password ? 'text-danger' : ''"></i>
                    <i @click="showPsw" class="far fa-eye-slash cursor-pointer" v-else :class="errors.password ? 'text-danger' : ''"></i>
                </div>
                <small v-if="errors.repeat_password" class="is_invalid">
                    <i class="fas fa-exclamation-triangle me-2"></i>
                    {{ errors.repeat_password }}
                </small>
            </div>
            <div class="col-12 mb-4">
                <label for="languages_id">
                    <i class="fas fa-flag cursor-pointer"></i>
                </label>
                <select v-model="languages_id" name="languages_id" class="form-control" id="languages_id" @change="setLanguages">
                    <option v-for="language in  this.$store.state.languages" :key="language.id" :value="language.id" :selected="language.id == this.$i18n.locale">
                        {{ language.name }}
                    </option>
                </select>
            </div>
            <div class="col-12 text-center mb-4">
                <label class="switch me-2">
                    <input type="checkbox" name="terms" id="terms" @click="setTerms">
                    <span class="slider round"></span>
                </label>
                <label class="d-inline text-light" for="terms">
                    <small :class="errors.terms ? 'text-danger' : ''">
                        <router-link to="/privacy" target="_blank" class="text-white text-underline">
                            {{ $t('auth.terms') }}
                        </router-link>
                    </small>
                </label>
            </div>
            <div class="col-12">
                <button v-if="!updateButton" type="submit" class="my-a btn w-100 text-white">
                    <i class="fas fa-user-plus me-2"></i>
                    {{ $t('auth.register') }}
                </button>
                <button v-else @click.prevent="updateSocialAccount" class="my-a btn w-100 text-white">
                    <i class="fa-solid fa-user-pen me-2"></i>
                    {{ $t('auth.update') }}
                </button>
            </div>
            <div class="col-12 mt-2">
                <router-link to="/log/in" class="btn btn-success text-white w-100">
                    <i class="fas fa-history me-2"></i>
                    {{ $t('auth.back') }}
                </router-link>
            </div>
        </div>
    </form>
    <transition name="fade-modal">
        <modal v-if="successModal" @close="successModal = false">
            <template v-slot:header>
                <div class="modal-header bg-success border-0 rounded-0 text-light">
                    <div class="d-flex align-items-center">
                        <i class="far fa-check-circle me-2 fs-4"></i>
                        <h5 class="modal-title">
                            {{ $t('modal.successModal.header.sign') }}
                        </h5>
                    </div>
                </div>
            </template>
        </modal>
    </transition>
    <transition name="fade-modal">
        <modal v-if="successEditedModal" @close="successEditedModal = false">
            <template v-slot:header>
                <div class="modal-header bg-success border-0 rounded-0 text-light">
                    <div class="d-flex align-items-center">
                        <i class="far fa-check-circle me-2 fs-4"></i>
                        <h5 class="modal-title">
                            {{ $t('modal.successModal.header.edit') }}
                        </h5>
                    </div>
                </div>
            </template>
        </modal>
    </transition>
</template>

<script>
import $ from 'jquery'
import { mapGetters } from "vuex"
import Modal from '../components/Modal.vue'
export default {
    name: 'Sign',
    components: {
        Modal
    },
    data() {
        return {
            name: '',
            surname: '',
            email: '',
            repeat_email: '',
            password: '',
            repeat_password: '',
            languages_id: this.$i18n.locale,
            terms: false,
            errors: {
                name: '',
                surname: '',
                email: '',
                repeat_email: '',
                password: '',
                repeat_password: '',
                terms: false
            },
            updateButton: false,
            successModal: false,
            successEditedModal: false,
            showPassword: false
        }
    },
    computed: {
        ...mapGetters(["apiPath"]),
    },
    methods: {
        setLanguages(event) {
            this.$i18n.locale = event.target.value
            this.allValidation()
        },
        allValidation() {
            this.validateName()
            this.validateSurname()
            this.validateEmail()
            this.validateRepEmail()
            this.validatePassword()
            this.validateRepPassword()
        },
        validateName() {
            if (this.name.trim().length == 0 ) {
                return this.errors.name = this.$t('errors.required')
            } else {
                delete this.errors.name
            }
        },
        validateSurname() {
            if (this.surname.trim().length == 0) {
                return this.errors.surname = this.$t('errors.required')
            } else {
                delete this.errors.surname
            }
        },
        validEmail (email) {
            var re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            return re.test(email);
        },
        validateEmail() {
            if (this.email.length == 0) {
                this.updateButton = false
                this.errors.email = this.$t('errors.required')
            } else if (!this.validEmail(this.email)) {
                this.updateButton = false
                this.errors.email = this.$t('errors.email.invalid')
            } else {
                this.$http
                    .post(`${this.apiPath}/account_exists`, {email: this.email}, {
                        headers: {
                            'x-api-key': this.$store.state.config.oms_api.key
                        }
                    })
                    .then(
                        (res) => {
                            if (res.data.email) {
                                if (res.data.google_id) {
                                    this.errors.email = this.$t('errors.email.existGoogle')
                                    this.updateButton = true
                                } else if (res.data.facebook_id) {
                                    this.errors.email = this.$t('errors.email.existFacebook')
                                    this.updateButton = true
                                } else {
                                    this.errors.email = this.$t('errors.email.exist')
                                    this.updateButton = false
                                }
                            } else {
                                this.updateButton = false
                                delete this.errors.email
                            }
                        }
                    )
                    .catch((err) => {
                        this.$store.state.serverModal = true
                        this.$store.state.errServer = err.message
                    })
            }
            this.validateRepEmail()
        },
        validateRepEmail() {
            if (this.repeat_email.length == 0) {
                return this.errors.repeat_email = this.$t('errors.required')
            } else if (!this.validEmail(this.repeat_email)) {
                return this.errors.repeat_email = this.$t('errors.email.invalid')
            } else if (this.repeat_email != this.email) {
                return this.errors.repeat_email = this.$t('errors.email.match')
            } else {
                delete this.errors.repeat_email
            }
        },
        validatePassword() {
            const format = /[ !@#$%^&*()_+\-=[\]{};':"\\|,.<>/?]/;

            if (this.password.length == 0 ) {
                return this.errors.password = this.$t('errors.required')
            } else if (this.password.length < 8) {
                return this.errors.password = this.$t('errors.password.length')
            } else if (!/\d/.test(this.password)) {
                return this.errors.password = this.$t('errors.password.number')
            } else if (!/[A-Z]/.test(this.password)) {
                return this.errors.password = this.$t('errors.password.uppercase')
            } else if (!/[a-z]/.test(this.password)) {
                return this.errors.password = this.$t('errors.password.lowercase')
            } else if (!format.test(this.password)) {
                return this.errors.password = this.$t('errors.password.special')
            } else {
                delete this.errors.password
            }
            this.validateRepPassword();

        },
        validateRepPassword() {

            if (this.repeat_password.length == 0 ) {
                return this.errors.repeat_password = this.$t('errors.required')
            } else if (this.repeat_password != this.password) {
                return this.errors.repeat_password = this.$t('errors.password.match')
            } else {
                delete this.errors.repeat_password
            }

        },
        setTerms() {
            this.terms = !this.terms
            this.errors.terms = false 
        },
        signUp () {
            this.$http
                .post(`${this.apiPath}/register`,
                    {
                        name: this.name,
                        surname: this.surname,
                        email: this.email,
                        repeat_email: this.repeat_email,
                        repeat_password: this.repeat_password,
                        password: this.password,
                        languages_id: this.languages_id,
                        terms: this.terms
                    },
                    {
                        headers: {
                            'x-api-key': this.$store.state.config.oms_api.key
                        }
                    }
                )
                .then(
                    (res) => {
                        if (res.data.errors) {
                            if (this.name === ''){
                                this.errors.name = this.$t('errors.required')
                            }
                            if (this.surname === ''){
                                this.errors.surname = this.$t('errors.required')
                            }
                            if (this.email === ''){
                                this.errors.email = this.$t('errors.required')
                            }
                            if (this.repeat_email === ''){
                                this.errors.repeat_email = this.$t('errors.required')
                            }
                            if (this.password === ''){
                                this.errors.password = this.$t('errors.required')
                            }
                            if (this.repeat_password === ''){
                                this.errors.repeat_password = this.$t('errors.required')
                            }
                            if (res.data.errors === 'Accept Terms') {
                                this.errors.terms = true
                            }
                        } else {   
                            this.successModal = true
                            setTimeout(() => {
                                this.successModal = false
                                this.$router.replace('/log/in')
                            }, 1500);
                        }
                    }
                )
                .catch((err) => {
                    this.$store.state.serverModal = true
                    this.$store.state.errServer = err.message
                })
        },
        updateSocialAccount() {
            this.$http
                .post(`${this.apiPath}/social_account/edit`,
                    {
                        name: this.name,
                        surname: this.surname,
                        email: this.email,
                        repeat_email: this.repeat_email,
                        password: this.password,
                        repeat_password: this.repeat_password,
                        languages_id: this.languages_id,
                        terms: this.terms
                    },
                    {
                        headers: {
                            "x-api-key": this.$store.state.config.oms_api.key,
                        }
                    }
                )
                .then((res) => {
                    if (res.data.errors) {
                        if (this.name === ''){
                            this.errors.name = this.$t('errors.required')
                        }
                        if (this.surname === ''){
                            this.errors.surname = this.$t('errors.required')
                        }
                        if (this.email === ''){
                            this.errors.email = this.$t('errors.required')
                        }
                        if (this.repeat_email === ''){
                            this.errors.repeat_email = this.$t('errors.required')
                        }
                        if (this.password === ''){
                            this.errors.password = this.$t('errors.required')
                        }
                        if (this.repeat_password === ''){
                            this.errors.repeat_password = this.$t('errors.required')
                        }
                        if (res.data.errors === 'Accept Terms') {
                            this.errors.terms = true
                        }
                    } else {   
                        this.successEditedModal = true
                        setTimeout(() => {
                            this.successEditedModal = false
                            this.$router.replace('/log/in')
                        }, 1500);
                    }
                })
                .catch((err) => {
                    this.$store.state.serverModal = true
                    this.$store.state.errServer = err.message
                })
        },
        showPsw() {
            this.showPassword = !this.showPassword

            if (this.showPassword)  {
                $('.input-psw').attr('type', 'text');
            } else {
                $('.input-psw').attr('type', 'password');
            }

        }
        
    }
}
</script>

<style lang="scss" scoped>
    .my-a {
        background-color: #f7911d;
        
        &:hover {
            background-color: #f7911d;
        }
    }

    input:checked + .slider {
        background-color: #4dd163;
    }
    .position-relative {
        .far {
            position: absolute;
            top: 50%;
            right: 2%;
            transform: translateY(-50%);
        }
    }
</style>