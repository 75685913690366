<template>
    <form v-if="!accountVerified" @submit.prevent="login" class="auth-form">
        <div class="d-flex flex-wrap align-items-center">
            <div class="col-12 mb-4">
                <label for="email">
                    <i class="fas fa-envelope cursor-pointer"></i>
                </label>
                <input v-model="email" @keyup="validateEmail" name="email" type="email" class="form-control" id="email" :placeholder="$t('auth.yourEmail')" :class="errors.email ? 'is_invalid' : ''">
                <small v-if="errors.email" class="is_invalid">
                    <i class="fas fa-exclamation-triangle me-2"></i>
                    {{ errors.email }}
                </small>
            </div>
            <div class="col-12 mb-4">
                <label for="password">
                    <i class="fas fa-lock cursor-pointer"></i>
                </label>
                <div class="position-relative">
                    <input v-model="password" @keyup="validatePassword" name="password" type="password" class="form-control input-psw" id="password" :placeholder="$t('auth.yourPassword')" :class="errors.password ? 'is_invalid' : ''">
                    <i @click="showPsw" class="far fa-eye cursor-pointer" v-if="showPassword" :class="errors.password ? 'text-danger' : ''"></i>
                    <i @click="showPsw" class="far fa-eye-slash cursor-pointer" v-else :class="errors.password ? 'text-danger' : ''"></i>
                </div>
                <small v-if="errors.password" class="is_invalid">
                    <i class="fas fa-exclamation-triangle me-2"></i>
                    {{ errors.password }}
                </small>
            </div>
            <div class="col-12">
                <button type="submit" class="btn btn-success w-100">
                    <i class="fas fa-sign-in-alt me-2"></i>
                    {{ $t('auth.login') }}
                </button>
            </div>
            <div class="col-12 my-4">
                <div class="d-flex justify-content-center align-items-center">
                    <hr>
                    <span class="mx-2 text-white">
                        {{ $t('auth.social.or') }}
                    </span>
                    <hr>
                </div>
            </div>
            <div class="col-12 d-flex justify-content-center align-items-center flex-wrap">
                <div class="col-12 d-flex justify-content-center mb-2">
                    <GoogleLogin :callback="loginWithGoogle"/>
                </div>
                <div class="col-12 d-flex justify-content-center mt-2">
                    <v-facebook-login @sdk-init="facebookSdkInit" :app-id="this.$store.state.config.social_login.facebook.appId" version="v14.0" class="d-none"></v-facebook-login>
                    <span class="w-75 py-2 px-3 bg-facebook text-white d-flex justify-content-center align-items-center rounded cursor-pointer" @click="loginWithFacebook">
                        <i class="fa-brands fa-facebook fs-4 me-2"></i>
                        {{$t('auth.social.login')}} Facebook
                    </span>
                </div>
            </div>
            <div class="col-12 mt-4">
                <hr>
            </div>
            <div class="col-12 my-2">
                <router-link to="/sign/up" class="my-a btn text-white w-100">
                    <i class="fas fa-user-plus me-2"></i>
                    {{ $t('auth.register') }}
                </router-link>
            </div>
            <div class="col-12 mt-2 ">
                <router-link :to="{name: 'EmailService', params: { request: 'forgot_password' }}" class="btn btn-light text-success w-100">
                    {{ $t('auth.emailSend.password') }}
                </router-link>
                <router-link :to="{name: 'EmailService', params: { request: 'confirm_email' }}" class="mt-2 btn btn-light text-success w-100">
                    {{ $t('auth.emailSend.notConfirm') }}
                </router-link>
            </div>
        </div>
    </form>
    <div v-else class="auth-form d-flex justify-content-between align-items-center flex-wrap">
        <div class="col-12 mb-4">
            <h6 class="text-white">
                {{ $t('auth.emailSend.emailSended') }}
            </h6>
        </div>
        <div class="col-12">
            <span class="mt-2 btn btn-success w-100" @click="login">
                <i class="fa-solid fa-check me-2"></i>
                {{ $t('auth.emailSend.confirmed') }}
            </span>
        </div>
        <div class="col-12">
            <router-link :to="{name: 'EmailService', params: { request: 'confirm_email' }}" class="mt-2 btn btn-light text-success w-100">
                {{ $t('auth.emailSend.notConfirm') }}
            </router-link>
        </div>
    </div>
    <transition name="fade-modal">
        <modal v-if="undefinedEmailModal">
            <template v-slot:header>
                <div class="modal-header border-0 rounded-0 text-light bg-google">
                    <div class="d-flex align-items-center">
                        <i class="fas fa-user-cog me-2"></i>
                        <h5 class="modal-title">
                            {{ $t('modal.settingsModal.header') }} 
                            {{ socialMethod == 'Google' ? 'Google' : 'Facebook' }}
                        </h5>
                    </div>
                </div>
            </template>
            <template v-slot:body>
                <div class="modal-body auth-form bg-google">
                  <h6 class="mb-2">
                    {{ $t('modal.settingsModal.body') }}
                  </h6>
                  <label for="social_email">
                      <i class="fas fa-envelope cursor-pointer"></i>
                  </label>
                  <input v-model="oProfile.socialEmail" @keyup="validateSocialEmail" name="social_email" type="email" class="form-control" :placeholder="$t('auth.yourEmail')" :class="errorsSocial.email ? 'is_invalid' : ''">
                  <small v-if="errorsSocial.email" class="is_invalid">
                      <i class="fas fa-exclamation-triangle me-2"></i>
                      {{ errorsSocial.email }}
                  </small>
                </div>
            </template>
            <template v-slot:footer>
                <div class="p-2 bg-google border-0 rounded-0">
                  <button v-if="socialMethod == 'Google'" class="btn btn-success w-100" @click="login_with_google(oProfile)" :disabled="Object.keys(errorsSocial).length != 0">
                    {{ $t('modal.settingsModal.footer.save&login') }}
                  </button>
                  <button v-else-if="socialMethod == 'Facebook'" class="btn btn-success w-100" @click="login_with_facebook(oProfile)" :disabled="Object.keys(errorsSocial).length != 0">
                    {{ $t('modal.settingsModal.footer.save&login') }}
                  </button>
                </div>
            </template>
        </modal>
    </transition>
</template>

<script>
import $ from 'jquery'
import VFacebookLogin from 'vue-facebook-login-component-next'
import { decodeCredential } from 'vue3-google-login'
import { mapMutations, mapGetters } from "vuex"
import Modal from '../components/Modal.vue'
export default {
    name: 'Login',
    components: {
        VFacebookLogin,
        Modal
    },
    data() {
        return {
            email: '',
            password: '',
            socialMethod: null,
            oProfile: {
              socialEmail: ''
            },
            errors: {
                email: '',
                password: ''
            },
            errorsSocial: {
                email: ''
            },
            undefinedEmailModal: false,
            showPassword: false,
            accountVerified: false,
            api_url: this.$store.state.config.api_url,
            api_port: this.$store.state.config.api_port
        }
    },
    computed: {
        ...mapGetters(["apiPath"]),
    },
    methods: {
        validEmail (email) {
            var re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            return re.test(email);
        },
        validateEmail () {
            if (this.email.length == 0) {
                return this.errors.email = this.$t('errors.required')
            } else if (!this.validEmail(this.email)) {
                return this.errors.email = this.$t('errors.email.invalid')
            } else {
                this.$http
                    .post(`${this.apiPath}/account_exists`, {email: this.email}, {
                        headers: {
                            'x-api-key': this.$store.state.config.oms_api.key
                        }
                    })
                    .then(
                        (res) => {
                            if (!res.data.email) {
                                this.errors.email = this.$t('errors.email.notExist')
                            } else {
                                delete this.errors.email
                            }
                        }
                    )
                    .catch((err) => {
                        this.$store.state.serverModal = true
                        this.$store.state.errServer = err.message
                    })
            }
        },
        validateSocialEmail () {
            if (this.oProfile.socialEmail.length == 0) {
                return this.errorsSocial.email = this.$t('errors.required')
            } else if (!this.validEmail(this.oProfile.socialEmail)) {
                return this.errorsSocial.email = this.$t('errors.email.invalid')
            } else {
                delete this.errorsSocial.email
            }
        },
        validatePassword () {
            if (this.password.length == 0 ) {
                return this.errors.password = this.$t('errors.required')
            } else {
                delete this.errors.password
            }
        },
        showPsw() {
            this.showPassword = !this.showPassword

            if (this.showPassword)  {
                $('.input-psw').attr('type', 'text');
            } else {
                $('.input-psw').attr('type', 'password');
            }

        },
        ...mapMutations(["setAccount", "setToken"]),
        login () {
            this.$http
                .post(`${this.apiPath}/login`, {email: this.email, password: this.password}, {
                    headers: {
                        'x-api-key': this.$store.state.config.oms_api.key
                    }
                })
                .then(
                    (res) => {
                        if (res.data.token){
                            if (res.data.account.token != null) {
                                this.accountVerified = true
                                this.$http
                                    .post(`${this.apiPath}/account/confirm_email_request`, { email: this.email }, {
                                        headers: {
                                            'x-api-key': this.$store.state.config.oms_api.key
                                        }
                                    })
                                    .catch((err) => {
                                        this.$store.state.serverModal = true
                                        this.$store.state.errServer = err.message
                                    })
                            } else {
                                const account = res.data.account
                                const token = res.data.token
                                this.setAccount(account)
                                this.setToken(token)
                                localStorage.setItem('session_account', JSON.stringify(res.data.account))
                                localStorage.setItem('session_token', JSON.stringify(res.data.token))
                                this.$router.replace('/travels')
                            }
                        } else{
                            switch (res.data) {
                                case 'Missing Email':
                                    this.errors.email = this.$t('errors.required')
                                    break
                                case 'Missing Password':
                                    this.errors.password = this.$t('errors.required')
                                    break
                                case 'Missing Email and Password':
                                    this.errors.email = this.$t('errors.required')
                                    this.errors.password = this.$t('errors.required')
                                    break
                                case 'Password Incorrect':
                                    this.errors.password = this.$t('errors.password.invalid')
                                    break
                            }
                        }
                    }
                )
                .catch((err) => {
                    this.$store.state.serverModal = true
                    this.$store.state.errServer = err.message
                })
        },
        loginWithGoogle(response) {
            this.socialMethod = 'Google'
            var profile = decodeCredential(response.credential)
            
            this.oProfile = {
                googleToken: response.credential,
                googleId: profile.sub,
                googleName: profile.given_name,
                googleSurname: profile.family_name,
                socialEmail: profile.email
            }
            
            if (this.oProfile.googleName == undefined) {
                this.oProfile.googleName = ''
            }
            if (this.oProfile.googleSurname == undefined) {
                this.oProfile.googleSurname = ''
            }

            if (this.oProfile.socialEmail == undefined) {
                this.undefinedEmailModal = true
            } else {
              this.login_with_google(this.oProfile)
            }
        },
        login_with_google(oProfile) {
            this.$http
                .post(`${this.apiPath}/login_with_google`, {oProfile},
                    {
                        headers: {
                            'x-api-key': this.$store.state.config.oms_api.key
                        }
                    }
                )
                .then(
                    (res) => {
                        if (res.data.account) {
                            const account = res.data.account;
                            const token = oProfile.googleToken;
                            this.setAccount(account);
                            this.setToken(token);
                            localStorage.setItem('session_account', JSON.stringify(res.data.account))
                            localStorage.setItem('session_token', JSON.stringify(oProfile.googleToken))
                            this.$router.replace('/travels')
                        }
                    }
                )
                .catch((err) => {
                    this.$store.state.serverModal = true
                    this.$store.state.errServer = err.message
                })
        },
        facebookSdkInit({ FB, scope }) {
            this.$store.state.FB = FB
            this.$store.state.scope = scope
            if (this.$store.state.account != null) {
                if (this.$store.state.account.facebook_id) {
                    this.$store.state.FB.logout()
                }
            }
        },
        loginWithFacebook() {
            this.socialMethod = 'Facebook'
            this.$store.state.FB.login(
                (res) => {
                    if (res.status === 'connected') {
                        this.$store.state.FB.api('/me', {fields: ['first_name', 'last_name', 'email']}, (profile) => {

                            this.oProfile = {
                                facebookToken: this.$store.state.FB.getAccessToken(),
                                facebookId: profile.id,
                                facebookName: profile.first_name,
                                facebookSurname: profile.last_name,
                                socialEmail: profile.email
                            }

                            if (this.oProfile.facebookName == undefined) {
                                this.oProfile.facebookName = ''
                            }
                            if (this.oProfile.facebookSurname == undefined) {
                                this.oProfile.facebookSurname = ''
                            }

                            if (this.oProfile.socialEmail == undefined) {
                                this.undefinedEmailModal = true
                            } else {
                                this.login_with_facebook(this.oProfile)
                            }
                        })
                    }
                }
            )
        },
        login_with_facebook(oProfile) {
            this.$http
                .post(`${this.apiPath}/login_with_facebook`, {oProfile}, {
                    headers: {
                        'x-api-key': this.$store.state.config.oms_api.key
                    }
                })
                .then(
                    (res) => {
                        if (res.data.account) {
                            const account = res.data.account;
                            const token = oProfile.facebookToken;
                            this.setAccount(account);
                            this.setToken(token);
                            localStorage.setItem('session_account', JSON.stringify(res.data.account))
                            localStorage.setItem('session_token', JSON.stringify(oProfile.facebookToken))
                            this.$router.replace('/travels')
                        }
                    }
                )
                .catch((err) => {
                    this.$store.state.serverModal = true
                    this.$store.state.errServer = err.message
                })
        }
    }
}
</script>

<style lang="scss" scoped>
    .my-a {
        background-color: #f7911d;

        &:hover {
            background-color: #f7911d;
        }
    }

    hr {
        width: 100%;
        border: 1px solid white;
    }

    .position-relative {
        .far {
            position: absolute;
            top: 50%;
            right: 2%;
            transform: translateY(-50%);
        }
    }

    .google-icon {
        width: 27px;
        height: 27px;
    }
</style>